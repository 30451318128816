<script lang="js">
  import { generateChart } from 'vue-chartjs'

  const CustomLine = generateChart('custom-line', 'horizontalBar')

  export default {
    extends: CustomLine,
    props: {
      type: { default: null },
    },
    mounted() {
      this.$root.$on(self.type + '_event', (data) => {
        this.renderChart(data.chartData, data.options)
      })
    },
  }
</script>
